import { useEffect, useState } from "react";
import "../../style/css/style.css";
import { OrganizationData, PlanData } from "../../interfaces/Pod.interface";
import { fetchOrganizationBySlug } from "../../services/PodService";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

interface TimeSlotProps {
  slotData: PlanData;
  slotIndex: number;
  isChecked: boolean;
  handleChange: (slotIndex: number) => void;
}

const TimeSlot: React.FC<TimeSlotProps> = ({
  slotIndex,
  slotData,
  isChecked,
  handleChange,
}) => {
  const location = useLocation();

  const { organizationName } = location.state || {
    organizationName: "default",
  };

  const [orgData, setOrgData] = useState<OrganizationData>();

  const handleInputChange = () => {
    handleChange(slotIndex);
  };

  useEffect(() => {
    const fetchOrgData = async () => {
      const responseData = await fetchOrganizationBySlug(organizationName);
      setOrgData(responseData);
    };

    fetchOrgData();
  }, []);
  return (
    <motion.div
      className={`card-parent flex max-w-sm p-3 border border-customBorderWhite rounded-[10px] shadow bg-customWhite7 ${
        orgData?.is_payment_enabled ? "h-[104px]" : "h-[76px]"
      }`}
      onClick={handleInputChange}
      role="button"
      tabIndex={0}
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: 1,
        y: 0,
        backgroundColor: isChecked ? "#EAF4FB" : "#F7F7F7",
        borderColor: isChecked ? "#2B95D6" : "#E2E2E2",
      }}
      exit={{ opacity: 0, y: 20 }}
      whileHover={{ scale: 1.05, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)" }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div>
        <p className="mb-2 font-semibold text-base tracking-tight  text-customGray">
          {slotData?.plan_name}
        </p>
        <div className="flex text-base tracking-tight">
          {orgData?.is_payment_enabled && (
            <>
              <div className="me-2 text-customEnvelopeColor  font-normal ">
                <del>₹{slotData?.original_amount}</del>
              </div>
              <div className="text-customBlue  font-semibold">
                ₹{slotData?.discounted_amount}
              </div>
            </>
          )}
        </div>
        <ul className="list-disc pl- flex flex-wrap">
          <li className="font-normal text-sm flex items-center">
            <span>•</span>
            <div className="text-customBlue font-semibold ms-1">
              {slotData?.duration} minutes
            </div>
            <div className="ml-1">of rest and relax</div>
          </li>
        </ul>
      </div>
      <motion.input
        type="radio"
        checked={isChecked}
        className="ml-auto radio-button"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        whileHover={{
          scale: 1.05,
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        }}
        whileFocus={{
          scale: 1.1,
          boxShadow: "0px 0px 10px rgba(0, 123, 255, 0.5)",
          borderColor: "#007BFF",
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      />
    </motion.div>
  );
};

export default TimeSlot;
