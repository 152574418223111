import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion"; // Import Framer Motion
import logo from "../../style/images/blue-logo-without-tagline.svg";

Modal.setAppElement("#root");

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed"; platform: string }>;
}

const PWAInstallModal: React.FC = () => {
  const [installPromptEvent, setInstallPromptEvent] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      setInstallPromptEvent(event as BeforeInstallPromptEvent);
      setIsModalOpen(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (installPromptEvent) {
      installPromptEvent.prompt();
      installPromptEvent.userChoice.then((choiceResult) => {
        setInstallPromptEvent(null);
        setIsModalOpen(false);
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AnimatePresence>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Install App Modal"
          className="fixed inset-0 flex items-start justify-center z-50"
          overlayClassName="fixed inset-0 bg-transparent pointer-events-none"
          shouldCloseOnOverlayClick={true}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="bg-white text-customBlack p-4 rounded-lg shadow-lg max-w-sm w-11/12 mx-auto flex items-center gap-4 fixed top-4  transform -translate-x-1/2 z-50"
          >
            <img src={logo} alt="App Icon" className="w-12 h-12 rounded-md" />
            <div className="flex-1">
              <p className="text-sm font-semibold">Install Urban Naps</p>
            </div>
            <button
              className="text-blue-400 font-bold text-sm hover:text-blue-500"
              onClick={handleInstallClick}
            >
              Install
            </button>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default PWAInstallModal;
