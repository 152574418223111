import { useState } from "react";
import logo from "../../style/images/blue-logo-without-tagline.svg";
import StarRating from "./StarRating";
import FeedbackForm from "./FeedBackForm";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Rating: React.FC = () => {
  const [rating, setRating] = useState(0);

  const navigate = useNavigate();

  const handleRatingSubmit = () => {
    navigate("/homepage");
  };

  return (
    <div className="parent-container bg-white flex-grow">
      <div className="flex flex-col items-center">
        <div className="mb-6 rounded-md pt-[127px]">
          <img
            src={logo}
            alt="urban naps"
            style={{ width: "139px", height: "73px" }}
          />
        </div>
        <div className="text-2xl text-center text-customBlack font-semibold px-12">
          Thank you for using UrbanNaps.
        </div>
        <div className="text-base text-customTextMildDarkGrey font-medium my-3">
          Don't forget to take your belongings
        </div>
      </div>
      <div className="flex flex-col items-center mt-10">
        <div className="text-base text-customTextDarkerBlue font-semibold my-3">
          Rate your experience
        </div>
        <StarRating setRating={setRating} rating={rating} />
        <FeedbackForm />
      </div>
      <div className="flex justify-center mt-4 pb-6">
        <motion.button
          className={`confirm-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-[357px] text-sm ${
            rating === 0
              ? "bg-customBackgroundGrey cursor-not-allowed"
              : "bg-customBlue"
          }`}
          disabled={rating === 0}
          onClick={handleRatingSubmit}
          whileTap={{ scale: 0.95 }}
        >
          DONE
        </motion.button>
      </div>
    </div>
  );
};

export default Rating;
