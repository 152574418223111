import React, { useEffect, useState } from "react";
import InfoCard from "./InfoCard";
import {
  fetchOrganizationBySlug,
  fetchPodDataByOrganization,
  fetchPodPlanById,
} from "../../services/PodService";
import { PodData } from "../../interfaces/Pod.interface";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoPodFound from "./NoPodFound";

const BottomSection: React.FC = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<PodData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [bookingDetails, setBookingDetails] = useState<{
    response: any;
    plan_name: string;
    id: string | null;
    start_time: string;
    end_time: string;
    status?: string;
  } | null>(null);

  const handleInactiveBooking = () => {
    toast.info(
      "We are redirecting you to the bookings page because your booking is active.",
      {
        onClose: () => navigate("/bookings"),
        autoClose: 3000,
      }
    );
  };

  useEffect(() => {
    const bookingDetails = localStorage.getItem("bookingDetails");
    if (bookingDetails) {
      const parsedDetails = JSON.parse(bookingDetails);
      if (parsedDetails && parsedDetails?.response.data.booking_id) {
        fetchPodPlanById(parsedDetails?.response.data.booking_id).then(
          (response) => {
            console.log(response.data, "response");
            setBookingDetails(response.data);
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    if (bookingDetails) {
      const endTime = new Date(bookingDetails.end_time);
      const startTime = new Date(bookingDetails.start_time);
      const currentTime = new Date();

      const startTimeString = bookingDetails.start_time;
      const endTimeString = bookingDetails.end_time;

      if (startTimeString && endTimeString) {
        if (
          currentTime < endTime &&
          currentTime > startTime &&
          bookingDetails.status !== "completed"
        ) {
          handleInactiveBooking();
        } else {
          localStorage.removeItem("bookingDetails");
          localStorage.removeItem("isCheckedIn");
          localStorage.removeItem("timeLeft");
        }
      }
    }
  }, [bookingDetails, navigate]);
  const getCompanyNameFromURL = (): string => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    return parts[0];
  };

  const organizationName = getCompanyNameFromURL();
  //commented code temporarily to prevent default selection of urbannaps
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const slug =
  //         organizationName === "localhost" ? "urbannaps" : organizationName;
  //       // const slug = organizationName;
  //       const responseData =  fetchOrganizationBySlug(slug);
  //       setData(responseData);
  //     } catch (error) {
  //       console.error("Failed to fetch organization data", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const slug =
          organizationName === "localhost" ? "urbannaps" : organizationName;

        const organizationData = await fetchOrganizationBySlug(slug);

        const responseData = await fetchPodDataByOrganization(
          organizationData?.id
        );

        // Sort the data based on the `available` property
        const sortedData = responseData.sort((a, b) => {
          // Prioritize "AVAILABLE" first
          if (a.available === "AVAILABLE" && b.available !== "AVAILABLE")
            return -1;
          if (a.available !== "AVAILABLE" && b.available === "AVAILABLE")
            return 1;

          // Check if they both start with "NEXT AVAILABLE IN"
          const aStartsWithNextAvailable =
            a.available.startsWith("NEXT AVAILABLE IN");
          const bStartsWithNextAvailable =
            b.available.startsWith("NEXT AVAILABLE IN");

          if (aStartsWithNextAvailable && bStartsWithNextAvailable) {
            const aMinutesMatch = a.available.match(/\d+/);
            const bMinutesMatch = b.available.match(/\d+/);

            if (aMinutesMatch && bMinutesMatch) {
              const aMinutes = parseInt(aMinutesMatch[0], 10);
              const bMinutes = parseInt(bMinutesMatch[0], 10);

              // Sort by the number of minutes (ascending)
              return aMinutes - bMinutes;
            }
          }

          // If only one of them starts with "NEXT AVAILABLE IN", prioritize it
          if (aStartsWithNextAvailable) return -1;
          if (bStartsWithNextAvailable) return 1;

          // If neither condition matches, maintain their relative order
          return 0;
        });

        setData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="p-2">
      <ToastContainer />
      {loading ? (
        <div className="flex justify-center items-center mt-36">
          <Loader isCompact />
        </div>
      ) : data.length === 0 ? (
        <div className="p-2 pt-32">
          <NoPodFound />
        </div>
      ) : (
        <>
          {/* Commenting this section temporarily for milestone 1 */}
          {/* <div className="mx-3 text-[15px] font-semibold text-center text-customBlue border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px">
              <li className="me-2 border-b-2 border-customBlue">
                <div className="inline-block p-4 tab-underline hover:border-customBlue border-b-2 border-transparent rounded-t-lg hover:text-customBlue dark:hover:text-gray-300">
                  Ground Floor
                </div>
              </li>
            </ul>
          </div> */}
          <div className="pt-4"></div>
          {data.map((item) =>
            item.is_active ? (
              <div className="p-2" key={item.id}>
                <InfoCard podData={item} />
              </div>
            ) : null
          )}
        </>
      )}
    </div>
  );
};

export default BottomSection;
