import React, { useState } from "react";
import "../../style/css/style.css";
import logo from "../../style/images/blue-logo-without-tagline.svg";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";

const Registration: React.FC = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleConfirmationEvent = () => {
    if (validateEmail(email)) {
      navigate("/check-email");
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-center",
      });
    }
  };

  const validateEmail = (input: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  return (
    <div className="parent-container bg-white flex-grow">
      <div className="flex flex-col items-center">
        <div className="mb-6 rounded-md pt-[127px]">
          <img
            src={logo}
            alt="urban naps"
            style={{ width: "139px", height: "73px" }}
          />
        </div>
        <div className="text-2xl mt-6 text-center text-customBlackReg font-bold px-12">
          Registration
        </div>
        <div className="mt-4 mb-6 border-b-2 border-customBlue rounded-md w-20"></div>
        <div className="text-[15px] text-center px-12 text-customGreyReg font-normal my-3">
          Please enter email address to get the verification link
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="relative w-[360px]">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            className="border border-customBorderLightGrey rounded-lg py-2 pl-10 pr-4 w-full text-[15px]"
          />
          <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-customEnvelopeColor" />
        </div>
      </div>
      <div className="flex justify-center mt-5">
        <motion.button
          className={`confirm-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-[360px] text-sm ${"bg-customBlue"}`}
          onClick={handleConfirmationEvent}
          whileTap={{ scale: 0.95 }}
        >
          CONFIRM
        </motion.button>
      </div>
    </div>
  );
};

export default Registration;
