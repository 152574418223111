// interface Product {
//   id: string;
//   discounted_amount: number;
// }

// interface User {
//   name: string;
//   email: string;
//   mobileNumber: string;
// }

// interface Pod {
//   id: string | null;
// }

// const displayRazorpay = async (
//   product: Product,
//   authToken: string,
//   user: User,
//   pod: any,
//   setOpen: (isOpen: boolean) => void
// ): Promise<void> => {
//   const loadScript = (src: string): Promise<boolean> => {
//     return new Promise((resolve) => {
//       const script = document.createElement("script");
//       script.src = src;
//       script.onload = () => resolve(true);
//       script.onerror = () => resolve(false);
//       document.body.appendChild(script);
//     });
//   };

//   try {
//     console.log(process.env, "Creating order...");
//     const orderRes = await axios.post(
//       `${process.env.REACT_APP_API_URL}/payment/create-order`,
//       {
//         productId: product.id,
//         receiptId: `order_${user.mobileNumber}_${pod.id}_${product.discounted_amount}`,
//         podId: pod.id,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       }
//     );

//     if (orderRes.status !== 200) {
//       throw new Error("Failed to create order");
//     }

//     const { data: razorpayOrder } = orderRes.data;

//     console.log("Loading Razorpay script...");
//     const res = await loadScript(
//       "https://checkout.razorpay.com/v1/checkout.js"
//     );

//     if (!res) {
//       alert("Razorpay SDK failed to load. Are you online?");
//       return;
//     }

//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY as string,
//       order_id: razorpayOrder.id,
//       name: "UrbanNaps",
//       description: "UrbanNaps Ahmedabad Airport",
//       handler: async (response: any) => {
//         try {
//           console.log("Verifying payment...");
//           const verifyRes = await axios.post(
//             `${process.env.REACT_APP_API_URL}/payment/verify`,
//             {
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_order_id: response.razorpay_order_id,
//               razorpay_signature: response.razorpay_signature,
//             },
//             {
//               headers: {
//                 Authorization: `Bearer ${authToken}`,
//               },
//             }
//           );

//           if (verifyRes.status === 200) {
//             toast.success("Payment Successful");
//             setOpen(true);
//           } else {
//             throw new Error("Payment verification failed");
//           }
//         } catch (error) {
//           console.error("Payment verification error:", error);
//           toast.error("Payment Verification Failed");
//         }
//       },
//       prefill: {
//         name: user.name,
//         email: user.email,
//         contact: user.mobileNumber,
//       },
//       notes: {
//         address: "UrbanNaps Corporate Office",
//       },
//       theme: {
//         color: "#F37254",
//       },
//     };

//     const paymentObject = new (window as any).Razorpay(options);
//     paymentObject.open();
//   } catch (error) {
//     console.error("Error creating Razorpay order:", error);
//     toast.error("An error occurred while creating the order");
//   }
// };

// export default displayRazorpay;
import { useNavigate } from "react-router-dom";
import { bookPodWithPlan } from "../services/PodService";
import { toast } from "react-toastify";
import logo from "../style/images/blue-logo-without-tagline.svg";

const useRazorpay = () => {
  const navigate = useNavigate();

  const displayRazorpay = async (
    amount: number,
    selectedSlot: number,
    selectedPodId: string | null | undefined,
    data: any
  ) => {
    console.log(
      data,
      `${process.env.REACT_APP_RAZORPAY_KEY_ID}:${process.env.REACT_APP_RAZORPAY_KEY_SECRET}`
    );
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY as string,
        amount: data?.discounted_amount * 100, // Amount should be in paisa
        currency: "INR",
        name: "UrbanNaps",
        description: `Booking for ${data[selectedSlot]?.plan_name} for ${data?.duration} minutes`,
        image: { logo },
        order_id: "order_ObIB6bGFYbTEog",
        notes: {
          address: "UrbanNaps Corporate Office",
        },
        theme: {
          color: "#0E97E6",
        },
        handler: (response: any) => {
          console.log("Payment successful", response);
          if (selectedSlot !== null) {
            const postDataToSend = {
              plan_id: data[selectedSlot]?.id,
              pod_id: selectedPodId,
            };
            bookPodWithPlan(postDataToSend)
              .then((response) => {
                console.log("Data posted successfully:", response);
                if (response.status.success) {
                  const bookingDetails = {
                    response,
                    planName: data[selectedSlot]?.plan_name,
                    pod_id: selectedPodId,
                  };
                  localStorage.setItem(
                    "bookingDetails",
                    JSON.stringify(bookingDetails)
                  );
                  navigate("/thankyou");
                } else {
                  toast.error("No plan found for given pod");
                  navigate("/homepage");
                }
              })
              .catch((error) => {
                console.error("Error posting data:", error);
                toast.error("An error occurred while booking the pod");
                navigate("/homepage");
              });
          }
        },
        modal: {
          ondismiss: () => {
            console.log("Checkout form closed");
          },
        },
      };

      // Step 3: Open Razorpay Checkout
      const razorpay = new (window as any).Razorpay(options);

      razorpay.on("payment.failed", (response: any) => {
        console.error("Payment failed", response.error);

        //TODO: to be removed in future
        razorpay.close();
        toast.error("Payment Failed, redirected to bookings page temporarily");
        navigate("/bookings");
      });

      razorpay.open();
    } catch (error) {
      console.error("Error displaying Razorpay checkout:", error);
    }
  };

  return { displayRazorpay };
};

export default useRazorpay;
