import React, { useState, useRef, useEffect, SetStateAction } from "react";
import dragger from "../../style/images/dragger.svg";
import "../../style/css/style.css";
import ModalComponent from "../common/Modal";
import CheckOut from "../Checkout/Checkout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchPodPlanById,
  postCheckoutPodPlanById,
  putRemoteControlPodPlanById,
} from "../../services/PodService";

type SwipeComponentProps = {
  isAtEnd: boolean;
  podId: string | null;
  bookingId: string | null;
  setIsAtEnd: React.Dispatch<SetStateAction<boolean>>;
};

const SwipeComponent: React.FC<SwipeComponentProps> = ({
  isAtEnd,
  podId,
  bookingId,
  setIsAtEnd,
}) => {
  const navigate = useNavigate();

  const [startX, setStartX] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckoutCancel, setIsCheckoutCancel] = useState(false);
  const [endTouchCount, setEndTouchCount] = useState(1);
  const [bookingDetails, setBookingDetails] = useState<{
    is_active: boolean;
    id: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    pod_id: string;
    plan_id: string;
    start_time: string;
    end_time: string;
    effactive_end_time: string | null;
    booking_source: string;
    session_id: string;
    order_id: string | null;
    status: string;
    pod: {
      is_active: boolean;
      id: string;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
      slug: string;
      title: string;
      description: string | null;
      type: string;
      master_chip_id: string;
      organization_id: string;
    };
    plan: {
      is_active: boolean;
      id: string;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
      plan_name: string;
      description: string | null;
      original_amount: number;
      discounted_amount: number;
      duration: number;
      organization_id: string;
    };
  } | null>(null);
  const swipeRef = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true);

  const [isCheckedIn, setIsCheckedIn] = useState(() => {
    const storedCheckedIn = localStorage.getItem("isCheckedIn");
    return storedCheckedIn ? JSON.parse(storedCheckedIn) : false;
  });

  const endTime = bookingDetails?.end_time;
  let minutesLeft: number = 0;

  if (endTime) {
    const currentTime = new Date();
    const endDate = new Date(endTime);

    const differenceInMilliseconds = endDate.getTime() - currentTime.getTime(); // Calculate the difference between current time and end time

    minutesLeft = Math.floor(differenceInMilliseconds / 1000);
  }

  const initialTimeLeft = () => {
    const savedTime = localStorage.getItem("timeLeft");
    if (savedTime !== null) {
      return JSON.parse(savedTime);
    }
    return isCheckedIn ? minutesLeft : 120;
  };

  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (swipeRef.current) {
      const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
      const swipeWidth = swipeRef.current.offsetWidth;
      setOffsetX(parentWidth - swipeWidth);
      setIsAtEnd(true);
    }
    setIsModalOpen(false);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (isDragging && swipeRef.current) {
      const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
      const swipeWidth = swipeRef.current.offsetWidth;
      const newOffsetX = Math.min(
        Math.max(0, offsetX + event.touches[0].clientX - startX),
        parentWidth - swipeWidth
      );
      setOffsetX(newOffsetX);
      setStartX(event.touches[0].clientX);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (swipeRef.current) {
      const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
      const swipeWidth = swipeRef.current.offsetWidth;
      const threshold = (parentWidth - swipeWidth) / 2;
      if (offsetX >= threshold) {
        setOffsetX(parentWidth - swipeWidth);
        setIsAtEnd(true);
        handleCheckIn();
      } else {
        setOffsetX(0);
        setIsAtEnd(false);
        if (isCheckedIn) {
          setIsModalOpen(true);
          openModal();
        }
      }
    }
  };

  const handleCheckIn = () => {
    setIsCheckedIn(true);
    if (minutesLeft < 0) {
      setTimeLeft(0);
    } else {
      setTimeLeft(minutesLeft);
    }
  };

  useEffect(() => {
    const bookingDetails = localStorage.getItem("bookingDetails");
    if (bookingDetails) {
      const parsedDetails = JSON.parse(bookingDetails);
      if (parsedDetails && parsedDetails?.response.data.booking_id) {
        fetchPodPlanById(parsedDetails?.response.data.booking_id).then(
          (response) => {
            setBookingDetails(response.data);
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    const bookingDetails = localStorage.getItem("bookingDetails");
    const isCheckedIn = localStorage.getItem("isCheckedIn");

    if (bookingDetails) {
      const parsedDetails = JSON.parse(bookingDetails);
      if (parsedDetails && parsedDetails?.response.data.booking_id) {
        fetchPodPlanById(parsedDetails?.response.data.booking_id).then(
          (response) => {
            const endTime = new Date(response.data.end_time).getTime();
            const currentTime = new Date().getTime();
            if (currentTime < endTime && isCheckedIn === "true") {
              // Check if user is checked in before logging or setting time
              const timeDifferenceInSeconds = Math.floor(
                (endTime - currentTime) / 1000
              );
              console.log("isCheckedIn is false");

              setTimeLeft(timeDifferenceInSeconds);
            }
          }
        );
      }
    }
  }, []);

  const handleCheckOut = () => {
    setIsCheckedIn(false);
    setTimeLeft(120);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const calculateRotation = () => {
    if (swipeRef.current) {
      const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
      const swipeWidth = swipeRef.current.offsetWidth;
      return -(offsetX / (parentWidth - swipeWidth)) * 180;
    }
    return 0;
  };

  const calculateBackgroundColor = () => {
    if (swipeRef.current) {
      const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
      const swipeWidth = swipeRef.current.offsetWidth;
      const percentage = offsetX / (parentWidth - swipeWidth);
      const redStart = 243;
      const greenStart = 244;
      const blueStart = 246;
      const redEnd = 14;
      const greenEnd = 151;
      const blueEnd = 230;

      const redValue = Math.floor(redStart - percentage * (redStart - redEnd));
      const greenValue = Math.floor(
        greenStart - percentage * (greenStart - greenEnd)
      );
      const blueValue = Math.floor(
        blueStart - percentage * (blueStart - blueEnd)
      );

      return `rgb(${redValue}, ${greenValue}, ${blueValue})`;
    }
    return "rgb(243, 244, 246)";
  };

  const calculateTextColor = () => {
    if (swipeRef.current) {
      const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
      const swipeWidth = swipeRef.current.offsetWidth;
      const percentage = offsetX / (parentWidth - swipeWidth);
      const colorValue = Math.floor(0 + percentage * 255);
      return `rgb(${colorValue}, ${colorValue}, ${colorValue})`;
    }
    return "rgb(0, 0, 0)";
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft: any) => {
        // Prevent timeLeft from going below 0
        if (prevTimeLeft <= 0) {
          clearInterval(timer);
          return 0;
        }
        return Math.max(prevTimeLeft - 1, 0);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (!initialRender.current) {
      localStorage.setItem("timeLeft", JSON.stringify(timeLeft));
    }
  }, [timeLeft]);

  useEffect(() => {
    if (isCheckoutCancel && swipeRef.current) {
      const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
      const swipeWidth = swipeRef.current.offsetWidth;
      setOffsetX(parentWidth - swipeWidth);
      setIsAtEnd(true);
    }
  }, [isCheckoutCancel]);

  useEffect(() => {
    setEndTouchCount((prevTouchCount) => prevTouchCount + 1);
    if (isAtEnd && endTouchCount === 3) {
      // setTimeLeft(differenceInSeconds);
    }
  }, [isAtEnd]);

  const handleCheckOutConfirmed = () => {
    setIsAtEnd(false);
    localStorage.setItem("isCheckedIn", "false");
    handleCheckOut();
  };

  useEffect(() => {
    const handleInactiveBooking = () => {
      if (!toastActive) {
        if (isAtEnd) {
          putRemoteControlPodPlanById(podId, bookingId, { door: 1 });
        }
        setToastActive(true);
        localStorage.setItem("isDoorOpened", "false");
        toast.info("Your booking is now inactive.", {
          onClose: () => {
            setToastActive(false);
            navigate("/homepage");
          },
          autoClose: 3000,
        });
      }
    };
    if (bookingDetails) {
      const endTime = new Date(bookingDetails.end_time);
      const currentTime = new Date();
      const endTimeString = bookingDetails.end_time;

      if (
        currentTime > endTime ||
        !endTimeString ||
        bookingDetails.status === "completed"
      ) {
        setTimeout(() => {
          localStorage.removeItem("bookingDetails");
          localStorage.removeItem("isCheckedIn");
          localStorage.removeItem("timeLeft");
        }, 500);
        handleInactiveBooking();
      }
    }
  }, [bookingDetails, navigate, timeLeft]);

  const handleCheckout = async () => {
    try {
      const bookingDetails = localStorage.getItem("bookingDetails");

      if (bookingDetails) {
        const parsedDetails = JSON.parse(bookingDetails);

        const bookingId = parsedDetails?.response?.data?.booking_id;

        if (!bookingId) {
          return;
        }

        const apiResponse = await postCheckoutPodPlanById(bookingId);
        if (apiResponse.status.success) {
          navigate("/rating");
        } else {
          toast.error(apiResponse.status.errorMessage);
        }
      } else {
        console.error("No booking details found in localStorage.");
        toast.error(
          "Booking details are missing. Cannot proceed with checkout."
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("An error occurred while checking out. Please try again.");
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      console.log("navigate to homepage", isAtEnd);
      // navigate(isAtEnd ? "/rating" : "/homepage");
      if (isAtEnd) handleCheckout();
    }
  }, [timeLeft, isAtEnd, navigate]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    localStorage.setItem("isCheckedIn", JSON.stringify(isCheckedIn));
  }, [isCheckedIn]);

  useEffect(() => {
    const isCheckedIn = localStorage.getItem("isCheckedIn");
    if (isCheckedIn) {
      const parsedCheckInDetail = JSON.parse(isCheckedIn);
      if (swipeRef.current && parsedCheckInDetail === true) {
        const parentWidth = swipeRef.current.parentElement?.offsetWidth ?? 0;
        const swipeWidth = swipeRef.current.offsetWidth;
        setOffsetX(parentWidth - swipeWidth);
        setIsAtEnd(parsedCheckInDetail);
        setIsCheckedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isAtEnd && timeLeft === 0) {
      setIsModalOpen(true);
      openModal();
    }
  }, [timeLeft, isAtEnd]);

  return (
    <>
      <div
        className="card-parent swipe-parent flex max-w-sm border border-customBorderWhite rounded-[56px] shadow depth-container"
        style={{ backgroundColor: calculateBackgroundColor() }}
      >
        <div ref={swipeRef} className="pr-5">
          <div
            className="swipe-button swipe-container"
            style={{
              transform: `translateX(${offsetX}px) rotate(${calculateRotation()}deg)`,
              transition: isDragging ? "none" : "transform 0.3s ease",
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchEnd}
          >
            <img src={dragger} alt=">>>" />
          </div>
        </div>
        <div
          className={`swipe-text font-semibold text-base`}
          style={{
            color: calculateTextColor(),
            marginLeft: isAtEnd ? `${-offsetX / 4}px` : `-${offsetX / 5}px`,
          }}
        >
          Swipe to check-{isAtEnd ? "out" : "in"}
        </div>
        <div
          className={`swipe-text ${
            isAtEnd ? "text-white" : "text-customRed"
          } font-bold text-base`}
        >
          {formatTime(timeLeft)}
        </div>
        <ModalComponent isOpen={isModalOpen} onRequestClose={closeModal}>
          <CheckOut
            podId={podId}
            bookingId={bookingId}
            onClose={closeModal}
            setIsCheckoutCancel={setIsCheckoutCancel}
            onConfirmCheckOut={handleCheckOutConfirmed}
          />
        </ModalComponent>
      </div>
    </>
  );
};

export default SwipeComponent;
