import React, { useEffect, useState } from "react";
import info from "../../style/images/info-icon.svg";
import "../../style/css/style.css";
import {
  fetchRemoteControlPodPlanById,
  putRemoteControlPodPlanById,
} from "../../services/PodService";
import { toast } from "react-toastify";

type RemoteControlProps = {
  isAtEnd: boolean;
  selectedPodId: string | null | undefined;
  podId: string | null;
  bookingId: string | null;
  setPodId: React.Dispatch<React.SetStateAction<string | null>>;
  setBookingId: React.Dispatch<React.SetStateAction<string | null>>;
};

const RemoteControl: React.FC<RemoteControlProps> = ({
  isAtEnd,
  podId,
  bookingId,
  setPodId,
  setBookingId,
  selectedPodId,
}) => {
  const [remoteControlPodPlanData, setRemoteControlPodPlanData] =
    useState<any>(null);

  const fetchRemoteControlData = async (bookingId: string) => {
    if (!bookingId) return;

    try {
      const data = await fetchRemoteControlPodPlanById(bookingId);
      setRemoteControlPodPlanData(data?.data?.remote);
    } catch (error) {
      console.error("Failed to fetch remote control pod plan data:", error);
    }
  };

  useEffect(() => {
    const bookingDetails = localStorage.getItem("bookingDetails");

    if (bookingDetails) {
      const parsedDetails = JSON.parse(bookingDetails);
      if (parsedDetails) {
        const {
          pod_id,
          response: {
            data: { booking_id },
          },
        } = parsedDetails;

        setPodId(pod_id);
        setBookingId(booking_id);

        if (booking_id) {
          fetchRemoteControlData(booking_id);
        }
      }
    }
  }, []);

  const handleRemoteAction = async (action: {
    light?: number;
    door?: number;
  }) => {
    if (!selectedPodId) return;

    try {
      const response = await putRemoteControlPodPlanById(
        podId,
        bookingId,
        action
      );
      const isCheckedIn = localStorage.getItem("isCheckedIn");

      if (isCheckedIn) {
        if (response.status.success) {
          toast.success("Remote control action executed successfully.");
          setRemoteControlPodPlanData(response.data.remote);
        } else {
          toast.error(response.status.errorMessage);
        }
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.errorMessage || "An error occurred";
      toast.error(`Failed to execute action: ${errorMessage}`);
    }
  };

  useEffect(() => {
    const isDoorOpened = localStorage.getItem("isDoorOpened") === "true";

    if (isAtEnd && !isDoorOpened) {
      console.log("Door Opened on Check-in");
      putRemoteControlPodPlanById(podId, bookingId, { door: 1 });
      localStorage.setItem("isDoorOpened", "true");
    }
  }, [isAtEnd, podId, bookingId]);

  return (
    <div className="card-parent flex max-w-sm p-4 bg-customWhite4 border border-customBorderWhite rounded-[10px] shadow">
      <div className={`flex flex-1 ${!isAtEnd ? "disabled" : ""}`}>
        <div className="flex-grow flex items-center justify-center">
          <div
            className={`w-24 h-24 ${
              remoteControlPodPlanData?.light ? "bg-customBlue" : "bg-white"
            } rounded-full overflow-hidden shadow-md flex justify-center items-center`}
          >
            <div
              className="text-center"
              onClick={() => {
                handleRemoteAction({
                  light: remoteControlPodPlanData?.light ? 0 : 1,
                });
              }}
            >
              <p
                className={`${
                  remoteControlPodPlanData?.light
                    ? "text-white"
                    : "text-customTextDarkerBlue"
                } font-bold text-base`}
              >
                LIGHT
              </p>
              <p
                className={`${
                  remoteControlPodPlanData?.light
                    ? "text-white"
                    : "text-customTextDarkerBlue"
                } text-sm leading-[15px]`}
              >
                On/Off
              </p>
            </div>
          </div>
        </div>
        {/* commenting help me button temporarily */}
        {/* <div className="flex-grow flex items-center justify-center">
          <div className="w-24 h-24  bg-white rounded-full overflow-hidden shadow-md flex justify-center items-center">
            <div className="text-center">
              <div className="flex flex-col items-center">
                <img className="mb-1" src={info} alt="i" />
                <p
                  className={`text-customTextDarkerBlue text-xs font-semibold leading-[15px]`}
                >
                  HELP ME
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="flex-grow flex items-center justify-center">
          <div
            className={`w-24 h-24  ${
              remoteControlPodPlanData?.door ? "bg-customBlue" : "bg-white"
            } rounded-full overflow-hidden shadow-md flex justify-center items-center`}
          >
            <div
              className="text-center"
              onClick={() => {
                handleRemoteAction({
                  door: remoteControlPodPlanData?.door ? 0 : 1,
                });
              }}
            >
              <p
                className={`${
                  remoteControlPodPlanData?.door
                    ? "text-white"
                    : "text-customTextDarkerBlue"
                } font-bold text-base`}
              >
                DOOR
              </p>
              <p
                className={`${
                  remoteControlPodPlanData?.door
                    ? "text-white"
                    : "text-customTextDarkerBlue"
                } text-sm leading-[15px]`}
              >
                Open/Close
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoteControl;
