import { useEffect, useState } from "react";
import TimeSlot from "./TimeSlot";
import { useLocation, useNavigate } from "react-router-dom";
import "../../style/css/style.css";
import { PlanData } from "../../interfaces/Pod.interface";
import {
  bookPodWithPlan,
  fetchOrganizationBySlug,
  fetchPlanDataByPod,
} from "../../services/PodService";
import Loader from "../common/Loader";
import useRazorpay from "../../utils/displayRazorpay";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

interface TimeCardProps {
  selectedPodId?: string | null;
  timeExtended?: boolean;
  handleModalClose: () => void;
}

const TimeCard: React.FC<TimeCardProps> = ({
  selectedPodId,
  timeExtended,
  handleModalClose,
}) => {
  const navigate = useNavigate();
  const [selectedSlot, setSelectedSlot] = useState<number>(0);
  const [data, setData] = useState<PlanData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isPaymentEnabled, setIsPaymentEnabled] = useState<boolean>(false);
  const { displayRazorpay } = useRazorpay();

  const handleSlotChange = (slotIndex: number) => {
    setSelectedSlot(slotIndex);
  };
  const location = useLocation();

  let { organizationName } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      if (organizationName === "localhost") {
        const responseData = await fetchOrganizationBySlug("urbannaps");
        setIsPaymentEnabled(responseData?.is_payment_enabled);
      } else {
        const responseData = await fetchOrganizationBySlug(organizationName);
        setIsPaymentEnabled(responseData?.is_payment_enabled);
      }
    };

    fetchData();
  }, []);

  const handleConfirmClick = async () => {
    const product = data[selectedSlot];
    if (product) {
      if (isPaymentEnabled)
        await displayRazorpay(5, selectedSlot, selectedPodId, data);
      else if (selectedSlot !== null) {
        // logic for non payment enabled with booking
        const postDataToSend = {
          plan_id: data[selectedSlot]?.id,
          pod_id: selectedPodId,
        };
        bookPodWithPlan(postDataToSend)
          .then((response) => {
            console.log("API called successfully:", response);
            if (response.status.success) {
              const bookingDetails = {
                response,
                planName: data[selectedSlot]?.plan_name,
                pod_id: selectedPodId,
              };
              localStorage.setItem(
                "bookingDetails",
                JSON.stringify(bookingDetails)
              );
              navigate("/thankyou");
            } else {
              toast.error(response.status.errorMessage);
              handleModalClose();
            }
          })
          .catch((error) => {
            console.error("Error posting data:", error);
          });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchPlanDataByPod(selectedPodId);
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div className="time-card-container bg-white flex-grow">
        <div className="flex flex-col items-center">
          <div className="mt-4 mb-6 border-b-4 border-customBorderGrey rounded-md w-10"></div>
          <div className="text-lg text-customBlack font-semibold">
            Choose from the best
          </div>
          <div className="text-sm text-customTextGrey font-normal pb-4">
            Select your nap time
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center my-6">
            <Loader isCompact />
          </div>
        ) : data.length === 0 ? (
          <div className="flex justify-center items-center my-6 font-bold text-base">
            <div>No Plan found</div>
            <span role="img" aria-label="Sad face" className="ml-2">
              😔
            </span>
          </div>
        ) : (
          data
            .sort((a, b) => a.duration - b.duration)
            .map((item, index) => (
              <div className="p-2" key={item.id}>
                <TimeSlot
                  slotData={item}
                  slotIndex={index}
                  isChecked={selectedSlot === index}
                  handleChange={handleSlotChange}
                />
              </div>
            ))
        )}
        <div className="flex justify-center mt-4">
          <motion.button
            className={`confirm-button hover:bg-blue-700 text-white font-bold py-2 sm:py-2 sm:px-4 px-6 rounded-lg text-sm sm:text-base ${
              selectedSlot === null
                ? "bg-[#E0DDDD] cursor-not-allowed"
                : "bg-customBlue"
            } w-[95%] sm:w-[330px] h-[46px] mb-8`}
            onClick={handleConfirmClick}
            disabled={selectedSlot === null}
            whileTap={{ scale: 0.95 }}
          >
            CONFIRM
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default TimeCard;
