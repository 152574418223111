import React, { useEffect, useState } from "react";
import BookingCard from "./BookingCard";
import RemoteControl from "./RemoteControl";
import SwipeComponent from "./SwipeComponent";
import ModalComponent from "../common/Modal";
import TimeCard from "../TimeSlot/TimeCard";
import { fetchPodPlanById } from "../../services/PodService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BookingSection: React.FC = () => {
  const navigate = useNavigate();

  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeExtended, setTimeExtended] = useState(false);
  const [podId, setPodId] = useState<string | null>(null);
  const [bookingId, setBookingId] = useState<string | null>(null);
  const [bookingDetails, setBookingDetails] = useState<{
    is_active: boolean;
    id: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    pod_id: string;
    plan_id: string;
    start_time: string;
    end_time: string;
    effactive_end_time: string | null;
    booking_source: string;
    session_id: string;
    order_id: string | null;
    status: string;
    pod: {
      is_active: boolean;
      id: string;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
      slug: string;
      title: string;
      description: string | null;
      type: string;
      master_chip_id: string;
      organization_id: string;
    };
    plan: {
      is_active: boolean;
      id: string;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
      plan_name: string;
      description: string | null;
      original_amount: number;
      discounted_amount: number;
      duration: number;
      organization_id: string;
    };
  } | null>(null);

  useEffect(() => {
    const bookingDetails = localStorage.getItem("bookingDetails");
    if (bookingDetails) {
      const parsedDetails = JSON.parse(bookingDetails);

      if (parsedDetails && parsedDetails?.response.data.booking_id) {
        fetchPodPlanById(parsedDetails?.response.data.booking_id).then(
          (response) => {
            setBookingDetails(response.data);
          }
        );
      }
    }

    if (!bookingDetails) {
      toast.info("No booking found.", {
        onClose: () => navigate("/homepage"),
        autoClose: 3000,
      });
    }
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleExtendButtonClick = () => {
    setTimeExtended(true);
    setIsModalOpen(true);
  };

  return (
    <div className="p-2">
      <div className="mx-3 pt-4 text-[15px] font-semibold text-customTextDarkerBlue">
        My bookings
      </div>
      <div className="p-2">
        <BookingCard bookingDetails={bookingDetails} />
      </div>
      {/* commenting temporarily for milestone 1
      
      <div className="flex justify-center mt-4 px-2">
        <motion.button
          className={`confirm-button font-semibold py-2 px-4 rounded-lg w-full md:w-64 text-sm border h-[46px] ${
            isAtEnd
              ? "hover:bg-customDarkCyan hover:text-white text-customDarkCyan border-customDarkCyan"
              : "bg-gray-200 text-gray-500 border-gray-200 cursor-not-allowed"
          }`}
          disabled={!isAtEnd}
          onClick={handleExtendButtonClick}
          whileTap={{ scale: 0.95 }}
        >
          EXTEND TIME
        </motion.button>
      </div> */}
      <div className="mx-3 pt-6 text-[15px] font-semibold text-customTextDarkerBlue">
        Remote control
      </div>
      <div className="p-2">
        <RemoteControl
          isAtEnd={isAtEnd}
          selectedPodId={bookingDetails && bookingDetails?.id}
          podId={podId}
          bookingId={bookingId}
          setPodId={setPodId}
          setBookingId={setBookingId}
        />
      </div>
      <div className="p-2">
        <SwipeComponent
          isAtEnd={isAtEnd}
          podId={podId}
          bookingId={bookingId}
          setIsAtEnd={setIsAtEnd}
        />
      </div>
      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Pod Info"
      >
        <TimeCard
          selectedPodId={bookingDetails && bookingDetails?.id}
          timeExtended={timeExtended}
          handleModalClose={handleModalClose}
        />
      </ModalComponent>
    </div>
  );
};

export default BookingSection;
