import React, { useEffect, useState } from "react";
import pod from "../../style/images/pod.svg";
import pin from "../../style/images/grey_pin.svg";
import time_duration from "../../style/images/time_duration.svg";
import { convertToIST } from "../../utils/timeZoneUtils";

type BookingCardProps = {
  bookingDetails: {
    is_active: boolean;
    id: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    pod_id: string;
    plan_id: string;
    start_time: string;
    end_time: string;
    effactive_end_time: string | null;
    booking_source: string;
    session_id: string;
    order_id: string | null;
    status: string;
    pod: {
      is_active: boolean;
      id: string;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
      slug: string;
      title: string;
      description: string | null;
      type: string;
      master_chip_id: string;
      organization_id: string;
    };
    plan: {
      is_active: boolean;
      id: string;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
      plan_name: string;
      description: string | null;
      original_amount: number;
      discounted_amount: number;
      duration: number;
      organization_id: string;
    };
  } | null;
};
const BookingCard: React.FC<BookingCardProps> = ({ bookingDetails }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (bookingDetails) {
      setLoading(false);
    }
  }, [bookingDetails]);

  return (
    <div className="card-parent flex max-w-sm p-4 bg-white border border-customBorderWhite rounded-[10px] shadow">
      {loading ? (
        <>
          <div className="h-[65px] w-[65px] mr-4 bg-gray-300 animate-pulse rounded-md"></div>
          <div className="flex flex-col w-full">
            <div className="h-6 w-3/5 bg-gray-300 animate-pulse rounded mb-3"></div>
            <div className="flex items-center mt-2">
              <div className="h-[15px] w-[15px] bg-gray-300 animate-pulse rounded-full mr-2"></div>
              <div className="h-4 w-2/3 bg-gray-300 animate-pulse rounded"></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <img className="h-[65px] w-[65px] mr-4" src={pod} alt="pod" />
          <div>
            <p className="font-semibold text-xl tracking-tight  text-customDarkCyan">
              {bookingDetails?.pod?.title}
            </p>
            {/* <div className="flex pt-2 items-center">
          <img
            src={pin}
            alt="location"
            className="inline-block"
            style={{ width: "11px", height: "15px" }}
          />
          <div className="text-sm ps-2 inline-block text-customTextDarkGrey">
            Ground Floor
          </div>
        </div> */}
            <div className="flex pt-2 items-center">
              <img
                src={time_duration}
                alt="location"
                className="inline-block"
                style={{ width: "11px", height: "15px" }}
              />
              <div className="text-sm ps-2 inline-block text-customTextDarkGrey">
                {convertToIST(bookingDetails?.start_time ?? "")} -{" "}
                {convertToIST(bookingDetails?.end_time ?? "")}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingCard;
